<template>
  <!-- eslint-disable -->
  <decorator-icon v-bind="attrs">
    <path
        d="M24 8C15.1795 8 8 15.1795 8 24C8 32.8205 15.1795 40 24 40C32.8205 40 40 32.8205 40 24C40 15.1795 32.8205 8 24 8ZM24 37.5385C16.5333 37.5385 10.4615 31.4667 10.4615 24C10.4615 16.5333 16.5333 10.4615 24 10.4615C31.4667 10.4615 37.5385 16.5333 37.5385 24C37.5385 31.4667 31.4667 37.5385 24 37.5385Z"
        fill="inherit"/>
    <path
        d="M25.2308 30.1535V22.7689C25.2308 22.0919 24.6769 21.5381 24 21.5381H22.7692C22.0923 21.5381 21.5385 22.0919 21.5385 22.7689C21.5385 23.4458 22.0923 23.9996 22.7692 23.9996V31.3842C22.7692 32.0612 23.3231 32.615 24 32.615H25.2308C25.9077 32.615 26.4615 32.0612 26.4615 31.3842C26.4615 30.7073 25.9077 30.1535 25.2308 30.1535Z"
        fill="inherit"/>
    <path
        d="M24 19.0771C25.0196 19.0771 25.8461 18.2505 25.8461 17.2309C25.8461 16.2113 25.0196 15.3848 24 15.3848C22.9804 15.3848 22.1538 16.2113 22.1538 17.2309C22.1538 18.2505 22.9804 19.0771 24 19.0771Z"
        fill="inherit"/>
  </decorator-icon>
</template>

<script>
import DecoratorIcon from '../decorator/dp-icon-decorator'
import MixinIcon from '../mixin/dp-icon-mixin'

/**
 * @group Components-icon-svg
 * Standard components
 */
export default {
  name: 'dp-info-icon',
  mixins: [MixinIcon],
  components: {
    DecoratorIcon
  },
  data() {
    return {
      viewBox: '0 0 48 48'
    }
  }
}
</script>
